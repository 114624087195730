<template>
  <el-main>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="content" label="消息内容"></el-table-column>
      <el-table-column prop="create_time" label="发送时间">
        <template v-slot="{ row }">
          {{ getDateformat(row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="{row}">
          <el-button type="text" size="small" @click="getSendResult(row.id)">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="详情" :visible.sync="showResult" width="600px">
      <el-form>
        <el-form-item label="发送状态">{{resultInfo.status == 0 ? '未发送' : resultInfo.status == 1 ? '已发送' : resultInfo.status == 2 ? '因客户不是好友导致发送失败' : '因客户已经收到其他群发消息导致发送失败' }}</el-form-item>
        <el-form-item v-show="resultInfo.send_time" label="发送时间">{{getDateformat(resultInfo.send_time)}}</el-form-item>
      </el-form>
    </el-dialog>
    <Paging class="paging" :total="total_number" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import Paging from '../../../components/paging.vue';
import { getDateformat } from '../../../util/getDate';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      list: [],
      page: 1,
      rows: 10,
      total_number: 0,
      showResult: !1,
      resultInfo: {},
    };
  },
  created() {
    this.getSendLog();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getSendLog();
    },
    getSendLog() {
      this.$axios
        .post(this.$api.user.wxwork.sendMessageLog, {
          external_userid: this.$route.query.external_userid,
          page: this.page,
          rows: this.rows,
        })
        .then(res => {
          if (res.code == 0) {
            this.list = res.result.list;
            this.total_number = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getSendResult(id) {
      this.$axios
        .post(this.$api.user.wxwork.sendMessageResult, {
          id: id,
        })
        .then(res => {
          if (res.code == 0) {
            this.showResult = !0;
            this.resultInfo = res.result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>